// Dependency
import React from "react";
import { graphql } from "gatsby";
import SwiperCore, {Mousewheel} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import Img from "gatsby-image";


// Components
import Hero from "../../components/hero/hero";
import Layout from "../../components/layouts";
import CenteredContent from "../../components/centered-content";
import TeamCard from "../../components/cards/team-card";
import Sticky from "../../components/sticky/sticky";
import StickyText from "../../components/sticky/sticky-text";
import InstaFeed from "../../components/instafeed/instafeed";
import FooterTrust from "../../components/trustpilot/footer-trustpilot";


// Styles
import * as ourStoryStyles from "./our-story.module.scss";
import * as stickyStyles from '../../components/sticky/sticky.module.scss';


// install Swiper modules
SwiperCore.use([Mousewheel]);

//GraphQL to get Dato data
export const query = graphql`
query ( $locale: String! = "en" ){
    datoCmsOurStory (locale: {eq: $locale}){
        locale
        title
        heroDesktop {
            fluid(maxWidth: 1500){
                ...GatsbyDatoCmsFluid
            }
        }
        heroMobile{
            fluid{
                ...GatsbyDatoCmsFluid
            }
        }
        description
        scrollComponents{
            text
            images{
                fluid{
                    ...GatsbyDatoCmsFluid
                }
            }
            textToRight
        }
        team {
            name
            image{
                fluid{
                    ...GatsbyDatoCmsFluid
                }
            }
        }
        seoMetaTags {
            tags
            id
        }
    }
}
`

// Template
class OurStory extends React.Component {

    render(){
        const data = this.props.data.datoCmsOurStory;
        const mediaData = {
            videoSrcURL: data && data.desktopVideo ? data.desktopVideo.url : '',
            videoSrcURLMobile: data && data.mobileVideo ? data.mobileVideo.url : '',
        }
        const sources = [
            data.heroMobile.fluid ,
            {
                ...data.heroDesktop.fluid ,
                media: `(min-width: 900px)`,
            },
        ]

        return(
            <Layout  seoData={data.seoMetaTags} country={this.props.pageContext.country}  location={this.props.location}>
                <Hero
                    sources={sources}
                    mediaData={mediaData}
                >
                 {/*   {
                        data && data.title && <h1>{data.title}</h1>
                    }*/}

                </Hero>
                <CenteredContent>
                    {
                        data && data.description && <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
                    }
                </CenteredContent>

                <Sticky>
                    <StickyText>
                        {
                            data && data.scrollComponents[0].text && <div dangerouslySetInnerHTML={{ __html: data.scrollComponents[0].text }}></div>
                        }
                    </StickyText>
                    <div className={stickyStyles.cdStickyGridRight}>
                        {data && data.scrollComponents[0].images && data.scrollComponents[0].images.map( (stickyImage, index) => {
                            return (
                                <div key={index} className={`${stickyStyles.cdStickyGridImg}`}>
                                    <Img  alt="" fluid={stickyImage.fluid} />
                                </div>
                            )
                        } )}
                    </div>
                </Sticky>

                <section className={ourStoryStyles.cdTeamSection}>
                    <div className="cd-max-width">
                        <div className="cd-swiper-wrapper">
                            <Swiper className={`cd-team-swiper cd-swiper`}
                                    spaceBetween={35}
                                    slidesPerView={'auto'}
                                    slidesPerGroup={1}
                                    grabCursor={true}
                                    mousewheel={{
                                        forceToAxis: true,
                                        releaseOnEdges: true,
                                    }}
                                    direction={'horizontal'}
                                    watchSlidesProgress={true}
                                    breakpoints={{
                                        1100: {
                                            spaceBetween: 35,
                                            slidesPerView: 'auto',
                                            slidesPerGroup: 1
                                        },
                                        600: {
                                            spaceBetween: 35,
                                            slidesPerView: 'auto',
                                            slidesPerGroup: 1
                                        },
                                        500:{
                                            spaceBetween: 20,
                                            slidesPerView: 2,
                                            slidesPerGroup: 1
                                        }
                                    }}
                            >
                                {data && data.team && data.team.map( (teamCard, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <TeamCard data={teamCard} />
                                        </SwiperSlide>
                                    )
                                } )}
                            </Swiper>
                        </div>
                    </div>
                </section>

                <Sticky cname={stickyStyles.cdStickyGridReverse}>
                    <StickyText>
                        {
                            data && data.scrollComponents[1].text && <div dangerouslySetInnerHTML={{ __html: data.scrollComponents[1].text }}></div>
                        }
                    </StickyText>
                    <div className={stickyStyles.cdStickyGridRight}>
                        {data && data.scrollComponents[1].images && data.scrollComponents[1].images.map( (stickyMoreImage, index) => {
                            return (
                                <div key={index} className={`${stickyStyles.cdStickyGridImg}`}>
                                    <Img  alt="" fluid={stickyMoreImage.fluid} />
                                </div>
                            )
                        } )}
                    </div>
                </Sticky>

            </Layout>
        )
    }
}

export default OurStory;