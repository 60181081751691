// Dependency
import React from "react";
import Img from "gatsby-image";
import {Link} from "gatsby";

// Styles
import * as heroStyles from './hero.module.scss'
import Video from "../video";

// Template
const Hero = (props) => {
    
    return (
        <section className={`${heroStyles.cdHero} cd-background-img`} id="cd-hero">
            {
                props.mediaData && props.mediaData.videoSrcURLMobile && <div className={heroStyles.cdHeroMobileVideo} >
                    <Video autoplay={'autoplay'} videoSrcURL={props.mediaData.videoSrcURLMobile} />
                </div>
            }
            {
                props.mediaData && props.mediaData.videoSrcURL ? <div className={heroStyles.cdHeroDesktopVideo} >
                    <Video autoplay={'autoplay'} videoSrcURL={props.mediaData.videoSrcURL} />
                </div>
                    :

                    props && props.sources && <Img alt="" className={heroStyles.cdHeroImg} fluid={props.sources} />

            }

            <div className={`${heroStyles.cdHeroContent} cd-hero-content `}>
                <div className="cd-max-width">
                    {props.children}
                </div>
            </div>
            {props.link ?
                <div className={heroStyles.cdHeroContentLink}>
                    <Link className="button button--outlined button--dark button--icon-right" to={props.link}>{props.buttonText} <i className="material-icons">east</i></Link>
                </div>
                :''
            }
           
        </section>
    )
}

export default Hero
