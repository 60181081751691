import React from "react";
import * as stickyStyles from './sticky.module.scss'

export default function Sticky(props) {

    return (

        <section className={`${stickyStyles.cdStickyGridSection} cd-dark-mode-dark`}>
            <div className="cd-max-width">
                <div className={`${stickyStyles.cdStickyGrid} ${props.cname}`}  >
                    {props.children}
                </div>
            </div>
        </section>
    )
}
