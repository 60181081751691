// Dependency
import React from "react"
import Img from "gatsby-image";

// Styles
import * as teamCardStyles from './team-card.module.scss'

// Template
const TeamCard = (props) => {

    return (
        <div className={`${teamCardStyles.cdTeamCard} cd-team-card`}>
            <div className={`${teamCardStyles.cdTeamCardImg} cd-background-img`}>
                <Img  alt="" fluid={props.data.image.fluid} />
            </div>
            {props.data.name ? <h4>{props.data.name}</h4> : null}
            {props.data.title ? <h5>{props.data.title}</h5> : null}
        </div>
    )
}

export default TeamCard
