import React from "react";
import * as centeredContentStyles from './centered-content.module.scss'

const CenteredContent = (props) => {
    return (
        <section className={`${centeredContentStyles.cdCcSection} ${props.cname} `}>
            <div className="cd-max-width">
                <div className={centeredContentStyles.cdCcContent}>
                    {props.children}
                </div>
            </div>
        </section>
    )
}

export default CenteredContent
