import React from "react";
import * as stickyStyles from "./sticky.module.scss";

const StickyText = (props) => {

    return (
        <div className={stickyStyles.cdStickyGridTextWrapper}>
            <div className={stickyStyles.cdStickyGridText}>
                {props.children}
            </div>
        </div>
    )
}

export default StickyText
